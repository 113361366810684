import styles from './PortfolioGraph.module.scss'
import Link from 'next/link'
import React, { useState } from 'react'
import { useMount } from 'react-use'
import { DateTime } from 'ts-luxon'
import BigNumber from '~/components/common/BigNumber'
import Button from '~/components/globals/Button'
import Circle from '~/components/globals/Circle'
import LineGraph, { type IDataset } from '~/components/graphs/LineGraph'
import TooltipCard from '~/components/graphs/TooltipCard'
import { useFormatters } from '~/hooks/formatters'
import { colorsHex, getColorByIndex, getColorNameByIndex } from '~/utils/colors'
import Card from '../globals/Card'
import { useTenant } from '~/hooks/tenant'
import SvgIcon from '~/components/common/SvgIcon'
import clsx from 'clsx'

type CompanyValuationProps = {
  type?: 'full' | 'compact'
  className?: string
  label?: string
  participantId?: string | number
}

export default function PortfolioGraph({
  className,
  label,
  participantId,
  type
}: CompanyValuationProps) {
  const { tenantApi } = useTenant()
  const { currencySymbol, formatAmount } = useFormatters()
  const [currentValues, setCurrentValues] = useState<{ label: string; value: number }[]>([])
  const [rawLabels, setRawLabels] = useState<string[]>([])
  const [labels, setLabels] = useState<string[]>([])
  const [graphData, setGraphData] = useState<IDataset[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  useMount(async () => {
    if (participantId) {
      const allTimeSeries = await tenantApi.$get(`participants/${participantId}/evaluations`)
      let dateFrom: string = DateTime.now().toISODate() ?? ''
      let dateTo: string = DateTime.now().toISODate() ?? ''
      const valueDateMap: Record<string, boolean> = {}
      for (const timeSeries of allTimeSeries) {
        Object.entries(timeSeries.series).forEach(([date, val]) => {
          dateFrom = dateFrom === '' || dateFrom > date ? date : dateFrom
          dateTo = dateTo === '' || dateTo < date ? date : dateTo
          const value = val as { bonus_vested: { value: number } }
          if (value.bonus_vested.value || value.bonus_vested.value === 0) {
            valueDateMap[date] = true
          }
        })
      }
      const valueDates: string[] = Object.keys(valueDateMap).sort()
      const allDates: string[] = []
      let date = dateFrom
      while (date <= dateTo) {
        allDates.push(date)
        date = DateTime.fromISO(date).plus({ days: 1 }).toISODate() ?? ''
      }
      setRawLabels(allDates)
      const newLabels = valueDates.map((date) => DateTime.fromISO(date).toISO() || '')
      setLabels(newLabels)
      setGraphData(
        allTimeSeries.map((timeSeries: any, i: number) => ({
          label: timeSeries.companyValuation.name,
          data: valueDates.map((date) => timeSeries.series[date]?.bonus_vested?.value ?? null),
          color: getColorByIndex(i),
          borderStyle: 'dashed',
          stepped: 'before',
          pointBorderColor: 'transparent',
          pointRadius: 0,
          segment: {
            borderDash: (ctx: any) => {
              return (DateTime.fromISO(newLabels[ctx.p1DataIndex]).toISODate() ?? '') >
                (DateTime.now().toISODate() ?? '')
                ? [6, 5]
                : []
            }
          }
        }))
      )
      setCurrentValues(
        allTimeSeries.map((timeSeries: any) => {
          return {
            label: timeSeries.companyValuation.name,
            value: Object.entries(timeSeries.series)
              .filter((e) => e[0] <= (DateTime.now().toISODate() ?? ''))
              .sort((e, f) => e[0].localeCompare(f[0]))
              .map((e) => (e[1] as any)?.bonus_vested?.value ?? null)
              .reduce((r, v) => (v === null ? r : v), 0)
          }
        })
      )
    }
    setLoading(false)
  })

  return (
    <Card
      className={clsx(className, { 'skeleton-container': loading })}
      headerClassName={type === 'compact' ? 'pb-16' : undefined}
      contentClassName={type === 'compact' ? 'pl-0 pr-8' : undefined}
      border={type !== 'compact'}
      title={label ?? 'Portfolio'}
    >
      <div className={clsx('flex justify-start px-24', styles.flex_wrap)}>
        {(loading ? [...Array(1)] : currentValues).map((currentValue, i: number) => (
          <BigNumber
            key={currentValue?.label ?? i}
            number={currentValue?.value ?? 0}
            prefixText={currencySymbol}
            label={
              <div className="flex items-center">
                <Circle
                  color={loading ? 'grey-100' : getColorNameByIndex(i)}
                  size="small"
                  className="mr-8"
                />{' '}
                <span className="skeleton-value">{currentValue?.label}</span>
              </div>
            }
            className="mr-32 pb-16"
          />
        ))}
      </div>
      <LineGraph
        type={type}
        tooltipComponent={TooltipCard}
        labels={labels}
        datasets={graphData}
        chartOptions={{
          elements: {
            point: {
              radius: 0
            }
          },
          interaction: {
            mode: 'index',
            intersect: false
          },
          spanGaps: true,
          scales: {
            x: {
              type: 'time',
              time: {
                unit: 'day',
                tooltipFormat: 'MMMM dd, yyyy'
              },
              ticks: {
                callback: function (value: any, index: number) {
                  if (loading) {
                    return null
                  }
                  const prevDate = (
                    rawLabels[index === 0 ? 0 : index - 1] ?? DateTime.now().toISODate()
                  ).split('-')
                  const date = (rawLabels[index] ?? DateTime.now().toISODate()).split('-')
                  if (prevDate[0] !== date[0]) {
                    return date[0]
                  }
                  return null
                }
              }
            },
            y: {
              ticks: {
                callback: (value: number) => formatAmount(value)
              }
            }
          }
        }}
        annotation={{
          line1: {
            type: 'line',
            borderColor: colorsHex.primary,
            borderWidth: 1,
            borderDash: [6, 5],
            scaleID: 'x',
            value: DateTime.now().toISO()
          }
        }}
      />
      {type === 'compact' ? (
        <div className="pl-24 pt-10">
          <Link href="/portfolio">
            <Button variant="text" size="xs">
              <>
                See the detail <SvgIcon icon="chevron-right" height={14} className="ml-4" />
              </>
            </Button>
          </Link>
        </div>
      ) : null}
    </Card>
  )
}
