import { useRouter } from 'next/router'
import React from 'react'
import DataTable, { type IHeader } from '~/components/common/DataTable'
import {
  AwardBeingVested,
  DisplayId,
  TotalAssetCount,
  PortfolioAssetValue
} from '~/components/common/DataTable/columns'
import Card from '~/components/globals/Card'
import { useList } from '~/hooks/list'
import Award from '~/models/AssetProcess/Award'
import { useTenant } from '~/hooks/tenant'
import clsx from 'clsx'

type Props = {
  participantId: string | number
  className?: string
  link?: string | JSX.Element
}

export default function AwardsBeingVested({ participantId, className, link }: Props) {
  const router = useRouter()
  const { tenantApi } = useTenant()
  const headers: IHeader<Award>[] = [
    {
      name: 'id',
      label: 'Id',
      sortable: true,
      component: ({ row }) => <DisplayId displayId={row.displayId} desc={row.esopSchemaName} />
    },
    {
      name: 'vesting',
      label: 'Vesting',
      align: 'end',
      component: ({ row }) => <AwardBeingVested award={row} />
    },
    {
      name: 'totalAssetCount',
      label: 'Assets count',
      sortable: true,
      align: 'end',
      component: ({ row }) => (
        <TotalAssetCount
          totalAssetCount={row.totalAssetCount as number}
          vestedAssetCount={row.vestedAssetCount as number}
          assetType={row.esopPlan?.assetType}
        />
      )
    },
    {
      name: 'vestedAssetValue',
      label: 'Current value',
      sortable: true,
      align: 'end',
      component: ({ row }) => (
        <PortfolioAssetValue vestedAssetValue={row.vestedAssetValue as number} />
      )
    }
  ]

  const list = useList({
    model: Award,
    defaultSortBy: 'id',
    defaultSortDesc: true,
    defaultPerPage: 5,
    autoRestoreRoute: false,
    autoUpdateRoute: false,
    fetch: (list) =>
      tenantApi.$get('asset/awards', {
        params: {
          status: 'vesting',
          participantId,
          ...list.getQuery(true)
        }
      })
  })
  const header = (
    <div className={'header flex px-24 pt-24 pb-16 items-center'}>
      <div className="fs-18 fw-500 flex-grow">
        <span className="skeleton-header">Awards being vested</span>
      </div>
    </div>
  )

  const onClick = (row: Record<string, any>) => {
    router.push(`/awards/${row.id}`)
  }

  return (
    <Card
      header={header}
      className={clsx(className, {
        'skeleton-container': list.isInitialLoading.value
      })}
      border={true}
    >
      <DataTable
        headers={headers}
        rows={list.rows.value}
        sortBy={list.sortBy.value}
        onSortBy={(value) => (list.sortBy.value = value)}
        sortDesc={list.sortDesc.value}
        onSortDesc={(value) => (list.sortDesc.value = value)}
        onSelect={onClick}
        loading={list.isLoading.value}
        initialLoading={list.isInitialLoading.value}
      />
      {link ? <div className="pt-24 skeleton-hide">{link}</div> : null}
    </Card>
  )
}
